import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7f191913 = () => interopDefault(import('../pages/check-email.vue' /* webpackChunkName: "pages/check-email" */))
const _4f1d6836 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _fa6d8d84 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _6673f203 = () => interopDefault(import('../pages/medications/index.vue' /* webpackChunkName: "pages/medications/index" */))
const _60c2ccbd = () => interopDefault(import('../pages/password-updated.vue' /* webpackChunkName: "pages/password-updated" */))
const _96b20ff0 = () => interopDefault(import('../pages/paylinks/index.vue' /* webpackChunkName: "pages/paylinks/index" */))
const _248162d8 = () => interopDefault(import('../pages/prescriptions/index.vue' /* webpackChunkName: "pages/prescriptions/index" */))
const _66c006d7 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _13969836 = () => interopDefault(import('../pages/registered.vue' /* webpackChunkName: "pages/registered" */))
const _3dc0bb03 = () => interopDefault(import('../pages/resend-confirmation.vue' /* webpackChunkName: "pages/resend-confirmation" */))
const _45c0718d = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _27385752 = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _899375c0 = () => interopDefault(import('../pages/settings/close-account.vue' /* webpackChunkName: "pages/settings/close-account" */))
const _058051c8 = () => interopDefault(import('../pages/settings/export.vue' /* webpackChunkName: "pages/settings/export" */))
const _60110c84 = () => interopDefault(import('../pages/settings/hospitals/index.vue' /* webpackChunkName: "pages/settings/hospitals/index" */))
const _15e50966 = () => interopDefault(import('../pages/settings/options.vue' /* webpackChunkName: "pages/settings/options" */))
const _259bd979 = () => interopDefault(import('../pages/settings/pharmacies.vue' /* webpackChunkName: "pages/settings/pharmacies" */))
const _4edd20a8 = () => interopDefault(import('../pages/settings/security.vue' /* webpackChunkName: "pages/settings/security" */))
const _7a4a3fb3 = () => interopDefault(import('../pages/settings/user.vue' /* webpackChunkName: "pages/settings/user" */))
const _1b13965c = () => interopDefault(import('../pages/settings/hospitals/new.vue' /* webpackChunkName: "pages/settings/hospitals/new" */))
const _16c8456f = () => interopDefault(import('../pages/settings/hospitals/_id/index.vue' /* webpackChunkName: "pages/settings/hospitals/_id/index" */))
const _40adf3fd = () => interopDefault(import('../pages/settings/hospitals/_id/edit.vue' /* webpackChunkName: "pages/settings/hospitals/_id/edit" */))
const _51a338f0 = () => interopDefault(import('../pages/embed/rx.vue' /* webpackChunkName: "pages/embed/rx" */))
const _a41fdfb4 = () => interopDefault(import('../pages/prescriptions/new.vue' /* webpackChunkName: "pages/prescriptions/new" */))
const _324533be = () => interopDefault(import('../pages/redirect/new.vue' /* webpackChunkName: "pages/redirect/new" */))
const _0ec9a8ce = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _9a1f5280 = () => interopDefault(import('../pages/paylinks/_slug.vue' /* webpackChunkName: "pages/paylinks/_slug" */))
const _bec054ba = () => interopDefault(import('../pages/prescriptions/_signature.vue' /* webpackChunkName: "pages/prescriptions/_signature" */))
const _cf8091f6 = () => interopDefault(import('../pages/new-password/_uid/_token.vue' /* webpackChunkName: "pages/new-password/_uid/_token" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/check-email",
    component: _7f191913,
    name: "check-email"
  }, {
    path: "/login",
    component: _4f1d6836,
    name: "login"
  }, {
    path: "/logout",
    component: _fa6d8d84,
    name: "logout"
  }, {
    path: "/medications",
    component: _6673f203,
    name: "medications"
  }, {
    path: "/password-updated",
    component: _60c2ccbd,
    name: "password-updated"
  }, {
    path: "/paylinks",
    component: _96b20ff0,
    name: "paylinks"
  }, {
    path: "/prescriptions",
    component: _248162d8,
    name: "prescriptions"
  }, {
    path: "/register",
    component: _66c006d7,
    name: "register"
  }, {
    path: "/registered",
    component: _13969836,
    name: "registered"
  }, {
    path: "/resend-confirmation",
    component: _3dc0bb03,
    name: "resend-confirmation"
  }, {
    path: "/reset-password",
    component: _45c0718d,
    name: "reset-password"
  }, {
    path: "/settings",
    component: _27385752,
    name: "settings",
    children: [{
      path: "close-account",
      component: _899375c0,
      name: "settings-close-account"
    }, {
      path: "export",
      component: _058051c8,
      name: "settings-export"
    }, {
      path: "hospitals",
      component: _60110c84,
      name: "settings-hospitals"
    }, {
      path: "options",
      component: _15e50966,
      name: "settings-options"
    }, {
      path: "pharmacies",
      component: _259bd979,
      name: "settings-pharmacies"
    }, {
      path: "security",
      component: _4edd20a8,
      name: "settings-security"
    }, {
      path: "user",
      component: _7a4a3fb3,
      name: "settings-user"
    }, {
      path: "hospitals/new",
      component: _1b13965c,
      name: "settings-hospitals-new"
    }, {
      path: "hospitals/:id",
      component: _16c8456f,
      name: "settings-hospitals-id"
    }, {
      path: "hospitals/:id/edit",
      component: _40adf3fd,
      name: "settings-hospitals-id-edit"
    }]
  }, {
    path: "/embed/rx",
    component: _51a338f0,
    name: "embed-rx"
  }, {
    path: "/prescriptions/new",
    component: _a41fdfb4,
    name: "prescriptions-new"
  }, {
    path: "/redirect/new",
    component: _324533be,
    name: "redirect-new"
  }, {
    path: "/",
    component: _0ec9a8ce,
    name: "index"
  }, {
    path: "/paylinks/:slug",
    component: _9a1f5280,
    name: "paylinks-slug"
  }, {
    path: "/prescriptions/:signature",
    component: _bec054ba,
    name: "prescriptions-signature"
  }, {
    path: "/new-password/:uid?/:token?",
    component: _cf8091f6,
    name: "new-password-uid-token"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
